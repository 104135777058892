import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import PlaceIcon from '@mui/icons-material/Place';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LockIcon from '@mui/icons-material/Lock';
/*
const hydrants = [
    { id: 1, name: 'Hydrant 1', status: 'closed', latitude: 40.208059, longitude: -8.893893, consumption: "30L", lastOpen: "20/07/2023"  },
    { id: 2, name: 'Hydrant 2', status: 'open', latitude: 40.213180, longitude: -8.888282, consumption: "20L", lastOpen: "23/07/2023" }
];*/
// Helper function to extract latitude from location string
const getLatitudeFromLocation = (location) => {
    const match = location.match(/q=(.*),/);
    return match ? parseFloat(match[1]) : null;
};

// Helper function to extract longitude from location string
const getLongitudeFromLocation = (location) => {
    const match = location.match(/,(.*)$/);
    return match ? parseFloat(match[1]) : null;
};

// Helper function to separate date from timestamp
const separateDateFromHours = (timestamp) => {
    const [date] = timestamp.split('T');
    const hour = timestamp.split('T');
    return date.split('-').reverse().join('/') + " " + hour[1];
};

const getCurrentEstimation = (timestamp, debit_m3_s) => {
    const now = Date.now();
    const elapsedTimeInSeconds = (now - timestamp) / 1000; // converting milliseconds to seconds
    const estimatedVolume = debit_m3_s * elapsedTimeInSeconds;
    return parseFloat(estimatedVolume.toFixed(1)); // in m^3 limited to 1 decimal place
}

const getDifferenceInMinutesSinceIsOpen = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp; // difference in milliseconds

    // Convert difference to minutes, hours, and days
    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let result = '';

    // Build the result string based on the elapsed time
    if (days > 0) {
        result += `${days} day${days > 1 ? 's' : ''}, `;
    }

    if (hours % 24 > 0) {
        result += `${hours % 24} hour${hours % 24 > 1 ? 's' : ''}, `;
    }

    if (minutes % 60 >= 0) {
        result += `${minutes % 60} minute${minutes % 60 > 1 ? 's' : ''} ago`;
    }

    // If result ends with ', ', trim the last comma and space
    if (result.endsWith(', ')) {
        result = result.slice(0, -2) + ' ago';
    }

    return result;
}
const getColorByOpenTime = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp; // difference in milliseconds
    const minutes = difference / (1000 * 60);

    if (minutes <= 10) {
        return '#F0DE03';
    } else if (minutes > 10 && minutes <= 30) {
        return '#F18802';
    } else {
        return '#b71c1c';
    }
}
// Transforming API response to hydrants object structure
const transformApiResponse = (apiResponse) => {
    return apiResponse.map(item => ({
        id: parseInt(item.hydrant_id),
        name: `Hydrant ${item.hydrant_id}`,
        status: item.current_state === true ? 'open' : 'closed',
        latitude: getLatitudeFromLocation(item.location),
        longitude: getLongitudeFromLocation(item.location),
        consumption: `${item.estimation.toFixed(2)} m³`,
        lastOpen: separateDateFromHours(item.date),
        openTime: getDifferenceInMinutesSinceIsOpen(item.open_time),
        currentEstimation: getCurrentEstimation(item.open_time, item.debit_m3_s),
        openColor:getColorByOpenTime(item.open_time),
    }));
};

function Dashboard({ userEmail, userToken }) {
    const [hydrants, setHydrants] = useState([]);
    const [open, setOpen] = useState(false);  // For modal visibility
    const [modalMessage, setModalMessage] = useState("");  // Message for modal
    const previousHydrantsRef = useRef([]);

    // Function to open modal
    const handleOpen = (message) => {
        setModalMessage(message);
        setOpen(true);
    };

    // Function to close modal
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let isCancelled = false;
        let previousHydrants = [...hydrants];  // Store the previous hydrants state

        const fetchHydrants = async () => {
            try {
                const response = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrants?client_email=${userEmail}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log("Fetched Data:", data); // Log the raw fetched data
                const newHydrants = transformApiResponse(data);
                console.log("Transformed Data:", newHydrants); // Log the transformed data


                newHydrants.forEach(newHydrant => {
                    const oldHydrant = previousHydrantsRef.current.find(h => h.id === newHydrant.id);
                    console.log(`Old Hydrant ${newHydrant.id} Status:`, oldHydrant ? oldHydrant.status : "Not found");
                    console.log(`New Hydrant ${newHydrant.id} Status:`, newHydrant.status);
                    if (oldHydrant && oldHydrant.status !== newHydrant.status) {
                        console.log(`Detected status change for Hydrant ${newHydrant.id}`);
                        handleOpen(`Hydrant ${newHydrant.id} is now ${newHydrant.status}`);
                    }
                });


                if (!isCancelled) {
                    previousHydrantsRef.current = newHydrants;
                    console.log("Stored Previous Hydrants:", previousHydrantsRef.current);
                    setHydrants(newHydrants);
                }
            } catch (error) {
                console.error('There was a problem fetching hydrants:', error);
            }
        };

        fetchHydrants();
        const interval = setInterval(fetchHydrants, 20000); // Refresh every 20 seconds

        return () => {
            isCancelled = true;
            clearInterval(interval);
        };
    }, [userToken, userEmail]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: '#b71c1c' }}>{"ALERT"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {modalMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: '#b71c1c' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Hydrant</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Consumption Estimation</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Usage</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Show on Map</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hydrants.map((hydrant) => (
                            <TableRow key={hydrant.id}>
                                <TableCell align="center"><Link style={{
                                    color: '#009BF4',
                                    fontWeight: 'bold',
                                    textDecoration: 'none'
                                }} to={`/hydrant/${hydrant.id}`}
                                >{hydrant.id}</Link></TableCell>
                                <TableCell align="center">
                                    {
                                        hydrant.status === 'open' ?
                                            <Tooltip title="Open">
                                                <WarningAmberIcon className="blink" sx={{ color: hydrant.openColor }} />
                                            </Tooltip> :
                                            <Tooltip title="Closed">
                                                <LockIcon />
                                            </Tooltip>
                                    }
                                </TableCell>

                                <TableCell align="center">{
                                    hydrant.status === 'open' ? hydrant.currentEstimation + ' m³' : 'Last estimation: ' + hydrant.consumption}</TableCell>
                                <TableCell align="center">{
                                    hydrant.status === 'open' ? hydrant.openTime : 'Last usage: ' + hydrant.lastOpen}</TableCell>
                                <TableCell align="center">
                                    <IconButton color="primary" onClick={() => {/* handle redirect to map with hydrant focus */ }}>
                                        <Link to={`/map#${hydrant.id}`}><PlaceIcon sx={{ color: '#E74600' }} /></Link>

                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </>
    );
}

export default Dashboard;
