import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import SecurityIcon from '@mui/icons-material/Security';
import FireHydrantAltIcon from '@mui/icons-material/FireHydrantAlt';
import AirIcon from '@mui/icons-material/Air';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';


const separateDateFromHours = (timestamp) => {
    const [date] = timestamp.split('T');
    const hour = timestamp.split('T');
    return date.split('-').reverse().join('/') + " " + hour[1];
};
// Helper function to extract latitude from location string
const getLatitudeFromLocation = (location) => {
    const match = location.match(/q=(.*),/);
    return match ? parseFloat(match[1]) : null;
};

// Helper function to extract longitude from location string
const getLongitudeFromLocation = (location) => {
    const match = location.match(/,(.*)$/);
    return match ? parseFloat(match[1]) : null;
};

function HydrantHistory({ userEmail, userToken }) {
    const { hydrantId } = useParams();  // Extract hydrant ID from the route parameters
    const [hydrantData, setHydrantData] = useState(null);
    useEffect(() => {
        const fetchHydrantHistory = async () => {
            try {
                const response = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrantSpecs?hydrant_id=${hydrantId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setHydrantData(data);
            } catch (error) {
                console.error('There was a problem fetching hydrant history:', error);
            }
        };

        fetchHydrantHistory();
    }, [hydrantId, userEmail, userToken]);

    // ... inside the HydrantHistory component after useEffect

    if (!hydrantData) return null;  // Wait until data is fetched

    const { location, cap_registed, caps_total, debit_m3_s } = hydrantData.specs;
    
    // Calculate total estimated consumption
    const totalEstimatedConsumption = parseFloat(hydrantData.historical_data.reduce(
        (acc, record) => acc + record.estimation,
        0
    ).toFixed(1));
    const ProfileSection = () => (
        <Box marginBottom={4} padding={2} boxShadow={3}>
            <Typography variant="h4" gutterBottom>
                Hydrant: {hydrantId}
            </Typography>
            <Divider style={{ marginBottom: '16px' }} />
            <Box display="flex" alignItems="center" marginBottom={2}>
                <Link to={`/map#${hydrantId}`}><PlaceIcon sx={{ color: '#E74600' }} /></Link>
                <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    <b>Location:</b>  {getLatitudeFromLocation(location)},{getLongitudeFromLocation(location)}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={2}>
                <AirIcon sx={{ color: '#009BF4' }} />
                <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    <b>Debit:</b> {debit_m3_s} m³/s
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={2}>
                <SecurityIcon sx={{ color: '#02CAD3' }} />
                <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    <b>Hydro Defence™ Caps:</b> {cap_registed}/{caps_total}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={2}>
                <FireHydrantAltIcon sx={{ color: '#b71c1c' }} />
                <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    <b>Total Estimated Water Consumption:</b> {totalEstimatedConsumption} m³
                </Typography>
            </Box>
        </Box>
    );


    // ... inside the HydrantHistory component after ProfileSection

    const HistoryTable = () => (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Usage Date</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Estimated Consumption</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hydrantData.historical_data.map((record, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{separateDateFromHours(record.date)}</TableCell>
                            <TableCell align="center">{record.estimation} m³</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );

    return (
        <div>
            <ProfileSection />
            <HistoryTable />
        </div>
    );


}

export default HydrantHistory;