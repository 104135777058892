import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleMap, LoadScriptNext, MarkerF, InfoWindowF } from '@react-google-maps/api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const mapContainerStyle = {
    height: "100%",
    width: "100%"
};

const defaultCenter = {
    lat: 40.213180,
    lng: -8.888282
};
const handleCopyLink = (hydrant) => {
    const googleMapsLink = `https://www.google.com/maps?q=${hydrant.latitude},${hydrant.longitude}`;
    navigator.clipboard.writeText(googleMapsLink)
        .then(() => {
            alert('Google Maps link copied to clipboard!');
        })
        .catch(err => {
            console.error('Could not copy text: ', err);
        });
};


// Helper function to extract latitude from location string
const getLatitudeFromLocation = (location) => {
    const match = location.match(/q=(.*),/);
    return match ? parseFloat(match[1]) : null;
};

// Helper function to extract longitude from location string
const getLongitudeFromLocation = (location) => {
    const match = location.match(/,(.*)$/);
    return match ? parseFloat(match[1]) : null;
};

// Helper function to separate date from timestamp
const separateDateFromHours = (timestamp) => {
    const [date] = timestamp.split('T');
    return date.split('-').reverse().join('/');
};
const getCurrentEstimation = (timestamp, debit_m3_s) => {
    const now = Date.now();
    const elapsedTimeInSeconds = (now - timestamp) / 1000; // converting milliseconds to seconds
    const estimatedVolume = debit_m3_s * elapsedTimeInSeconds;
    return parseFloat(estimatedVolume.toFixed(1)); // in m^3 limited to 1 decimal place
}

const getDifferenceInMinutesSinceIsOpen = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp; // difference in milliseconds

    // Convert difference to minutes, hours, and days
    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let result = '';

    // Build the result string based on the elapsed time
    if (days > 0) {
        result += `${days} day${days > 1 ? 's' : ''}, `;
    }

    if (hours % 24 > 0) {
        result += `${hours % 24} hour${hours % 24 > 1 ? 's' : ''}, `;
    }

    if (minutes % 60 >= 0) {
        result += `${minutes % 60} minute${minutes % 60 > 1 ? 's' : ''} ago`;
    }

    // If result ends with ', ', trim the last comma and space
    if (result.endsWith(', ')) {
        result = result.slice(0, -2) + ' ago';
    }

    return result;
}
const getColorByOpenTime = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp; // difference in milliseconds
    const minutes = difference / (1000 * 60);

    if (minutes <= 10) {
        return 'yellow';
    } else if (minutes > 10 && minutes <= 30) {
        return 'orange';
    } else {
        return 'red';
    }
}

const getIcon = (status,color) =>{

    if (color==='yellow') {
        return {
            url: status === 'Open' ? require('./images/fire-hydrant-yellow.svg').default : require('./images/fire-hydrant-black.svg').default,
            scaledSize: window.google ? new window.google.maps.Size(32, 32) : undefined
        };
    } else if (color==='orange') {
        return {
            url: status === 'Open' ? require('./images/fire-hydrant-orange.svg').default : require('./images/fire-hydrant-black.svg').default,
            scaledSize: window.google ? new window.google.maps.Size(32, 32) : undefined
        };
    } else {
        return{
            url: status === 'Open' ? require('./images/fire-hydrant-red.svg').default : require('./images/fire-hydrant-black.svg').default,
            scaledSize: window.google ? new window.google.maps.Size(32, 32) : undefined
        };
    }
}


// Transforming API response to hydrants object structure
const transformApiResponse = (apiResponse) => {
    return apiResponse.map(item => ({
        id: parseInt(item.hydrant_id),
        name: `Hydrant ${item.hydrant_id}`,
        status: item.current_state === true ? 'Open' : 'Close',
        latitude: getLatitudeFromLocation(item.location),
        longitude: getLongitudeFromLocation(item.location),
        consumption: `${item.estimation.toFixed(2)} m³`,
        lastOpen: separateDateFromHours(item.date),
        openTime: getDifferenceInMinutesSinceIsOpen(item.open_time),
        currentEstimation: getCurrentEstimation(item.open_time, item.debit_m3_s)+" m³",
        openColor:getColorByOpenTime(item.open_time),
    }));
};

function HydroDefenceMap({ userEmail, userToken }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [mapZoom, setMapZoom] = useState(12);
    const [selectedHydrant, setSelectedHydrant] = useState(null); // For tracking the clicked hydrant
    const [hydrants, setHydrants] = useState([]); // Initial empty state
    const [isMarkerVisible, setIsMarkerVisible] = useState(true);
    const [open, setOpen] = useState(false);  // For modal visibility
    const [modalMessage, setModalMessage] = useState("");  // Message for modal
    const hydrantsRef = useRef(hydrants);
    // Function to open modal
    const handleOpen = (message) => {
        console.log("handleOpen called with message:", message);
        setModalMessage(message);
        setOpen(true);
    };

    // Function to close modal
    const handleClose = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        let isCancelled = false;

        const fetchHydrants = async () => {
            try {
                const response = await fetch(`https://4cfxljq8qi.execute-api.eu-west-1.amazonaws.com/dev/hydrants?client_email=${userEmail}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                const newHydrants = transformApiResponse(data);

                if (!isCancelled) {
                    newHydrants.forEach(newHydrant => {
                        const oldHydrant = hydrantsRef.current.find(h => h.id === newHydrant.id);
                        if (oldHydrant && oldHydrant.status !== newHydrant.status) {
                            console.log(`Detected status change for Hydrant ${newHydrant.id} from ${oldHydrant.status} to ${newHydrant.status}`);
                            handleOpen(`Hydrant ${newHydrant.id} is now ${newHydrant.status}`);
                            setSelectedHydrant(newHydrant);
                        }
                    });
                    console.log("Previous hydrants:", hydrants);
                    console.log("New hydrants:", newHydrants);

                    setHydrants(newHydrants);
                    hydrantsRef.current = newHydrants;
                    const hydrantId = parseInt(location.hash.replace("#", ""), 10);
                    const hydrant = newHydrants.find(h => h.id === hydrantId);

                    if (hydrant) {
                        setMapCenter({
                            lat: hydrant.latitude,
                            lng: hydrant.longitude
                        });
                        setMapZoom(17);
                    } else {
                        setMapCenter(defaultCenter);
                        setMapZoom(12);
                    }
                }
            } catch (error) {
                console.error('There was a problem fetching hydrants:', error);
            }
        };

        fetchHydrants();

        const interval = setInterval(fetchHydrants, 20000); // Refresh every 20 seconds
        return () => {
            isCancelled = true;
            clearInterval(interval);
        };
    }, [location, userEmail, userToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsMarkerVisible(prev => !prev); // Toggle visibility
        }, 750); // blink every second

        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, []);

  

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: '#b71c1c' }}>{"ALERT"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {modalMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: '#b71c1c' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadScriptNext googleMapsApiKey="AIzaSyBypwJGJKzyume15VWHj4VdSI8qZwlLjVE">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={mapZoom}
                    center={mapCenter}
                >
                    {
                        window.google && hydrants.map(hydrant => {
                            const commonProps = {
                                key: hydrant.id,
                                position: { lat: hydrant.latitude, lng: hydrant.longitude },
                                clickable: true,
                                title: hydrant.name,
                                icon: getIcon(hydrant.status, hydrant.openColor),
                            };

                            // Static "Close" markers
                            if (hydrant.status === 'Close') {
                                return (
                                    <MarkerF
                                        {...commonProps}
                                        onClick={() => {
                                            navigate(`/map#${hydrant.id}`);
                                            setSelectedHydrant(hydrant);
                                        }}
                                        onMouseOver={() => setSelectedHydrant(hydrant)}
                                        onMouseOut={() => setSelectedHydrant(null)}
                                    />
                                );
                            }

                            // Blinking "Open" markers
                            if (hydrant.status === 'Open' && isMarkerVisible) {
                                return (
                                    <MarkerF
                                        {...commonProps}
                                        onClick={() => {
                                            navigate(`/map#${hydrant.id}`);
                                            setSelectedHydrant(hydrant);
                                        }}
                                        onMouseOver={() => setSelectedHydrant(hydrant)}
                                        onMouseOut={() => setSelectedHydrant(null)}
                                    />
                                );
                            }

                            // If neither condition is met, don't render any marker (although this case should not happen with your current data)
                            return null;
                        })
                    }


                    {selectedHydrant && mapZoom > 15 && (
                        <InfoWindowF
                            position={{ lat: selectedHydrant.latitude + 0.00035, lng: selectedHydrant.longitude }}
                            onCloseClick={() => setSelectedHydrant(null)}
                        >
                            <div>
                                <h4>{selectedHydrant.name}</h4>
                                <p><b>Status:</b> {selectedHydrant.status}</p>
                                <p><b>{selectedHydrant.status==='Open'?'Current Usage: ':'Last Usage: '}</b> {selectedHydrant.status==='Open'?selectedHydrant.openTime:selectedHydrant.lastOpen}</p>
                                <p><b>{selectedHydrant.status==='Open'?'Current Water Estimation Consumption: ':'Last Estimated Water Consumption: '}</b> {selectedHydrant.status==='Open'?selectedHydrant.currentEstimation:selectedHydrant.consumption}</p>
                                <p><b>Location:</b> {selectedHydrant.latitude},{selectedHydrant.longitude} <ContentCopyIcon sx={{ fontSize: 10 }} onClick={() => handleCopyLink(selectedHydrant)} /></p>
                            </div>
                        </InfoWindowF>
                    )}
                </GoogleMap>
            </LoadScriptNext>
        </>
    );
}

export default HydroDefenceMap;
