import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Dashboard from './Dashboard';
import HydroDefenceMap from './HydroDefenceMap';
import HydrantHistory from './HydrantHistory';
import { Auth } from 'aws-amplify';
import { Paper, List, ListItem, ListItemText, ListItemIcon, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

function CustomSignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [currentUser, setCurrentUser] = useState(null);

    const handleSignIn = async () => {
      try {
        const user = await Auth.signIn(username, password);
        
        // Check if a password change is required
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsChangePassword(true);
          setCurrentUser(user);  // Store the user object
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error('Error signing in', error);
        const errorMessage = error.message || 'An error occurred while signing in.';
        setModalMessage('Error signing in: ' + errorMessage);
        setOpen(true);
      }
  };
  

  const handleChangePassword = async () => {
    try {
      await Auth.completeNewPassword(
        currentUser,   // the stored user object
        newPassword    // the new password
      );
      window.location.reload();
    } catch (error) {
      console.error('Error changing password:', error);
      const errorMessage = error.message || 'An error occurred while changing the password.';
      setModalMessage('Error changing password: ' + errorMessage);
      setOpen(true);
    }
};


    const handleClose = () => {
        setOpen(false);
    };

    if (isChangePassword) {
        return (
            <div className="login-container">
                <Typography variant="h5" gutterBottom>
                    Hydro Defence™™ - Change Password
                </Typography>
                <Paper elevation={3} style={{ padding: '1rem' }}>
                    <TextField
                        fullWidth
                        type="password"
                        label="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        margin="normal"
                    />
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={handleChangePassword}
                        style={{ marginTop: '1rem' }}
                        fullWidth
                    >
                        Change Password
                    </Button>
                </Paper>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ color: '#b71c1c' }}>{"ALERT"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {modalMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} sx={{ color: '#b71c1c' }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return (
        <div className="login-container">
            <Typography variant="h5" gutterBottom>
                Hydro Defence™
            </Typography>
            <Paper elevation={3} style={{ padding: '1rem' }}>
                <TextField
                    fullWidth
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    type="password"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                />
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={handleSignIn}
                    style={{ marginTop: '1rem' }}
                    fullWidth
                >
                    Sign In
                </Button>
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: '#b71c1c' }}>{"ALERT"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {modalMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ color: '#b71c1c' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function App() {
    const [user, setUser] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userToken, setUserToken] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
                const email = currentUser.attributes.email;

                setUser(currentUser);
                setUserToken(jwtToken);
                setUserEmail(email);
            } catch {
                setUser(null);
                setUserToken(null);
                setUserEmail(null);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                setUser(currentUser);
            } catch {
                setUser(null);
            }
        };

        checkUser();
    }, []);

    const signOut = async () => {
        try {
            await Auth.signOut();
            setUser(null);
            window.location.reload();
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    return user ? (
        <Router>
            <div className="app-container">
                <aside className="sidebar">
                    <Typography variant="h5" gutterBottom>
                        Hydro Defence™
                    </Typography>
                    <Paper elevation={3}>
                        <List>
                            <ListItem button component={Link} to="/">
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem button component={Link} to="/map">
                                <ListItemIcon>
                                    <MapOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Map" />
                            </ListItem>
                            <ListItem button onClick={signOut}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sign Out" />
                            </ListItem>
                        </List>
                    </Paper>
                </aside>
                <main className="content">
                    <Routes>
                        <Route path="/" element={<Dashboard userEmail={userEmail} userToken={userToken} />} />
                        <Route path="/hydrant/:hydrantId" element={<HydrantHistory userEmail={userEmail} userToken={userToken} />} />
                        <Route path="/map" element={<HydroDefenceMap userEmail={userEmail} userToken={userToken} />} />
                    </Routes>
                </main>
            </div>
        </Router>
    ) : (
        <CustomSignIn />
    );
}

export default App;
